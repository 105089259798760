import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { Autocomplete, Typography, MenuItem,	FormControl, InputLabel, Select, Tooltip } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import * as constants from '../../../helpers/constants';
import useQuery from '../../../helpers/hooks/useQuery';
import { logout, setTokens } from '../../../store/slices/authSlice';
import ModalLoader from '../../ModalLoader';
import CardMedia from '@mui/material/CardMedia';
import placeholder from '../../../assets/images/placeholder.png';
import CircularProgress from '@mui/material/CircularProgress';
import InstantStoryboard from '../InstantStoryboard';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	outline: 'none',
	px: 4,
	width: {
		xs: '100%',
		md: '60vw',
	},
};

export default function AddSceneImages({
	open,
	setOpen,
	summary,
	list_of_acts,
	acts,
	scenes,
	setting,
	characters,
	storyCharacters,
	storySetting,
	setTokensFillModal,
	preventScroll
}) {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();
	const alert = useAlert();
	const query = useQuery();
	const projectId = () =>
		location.pathname.slice(9, location.pathname.length);
	const navigate = useNavigate();
	const token = useSelector((state) => state.auth.token);
	const [prompt, setPrompt] = useState('');
	const [generatedLinks, setGeneratedLinks] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [imageStyles, setImageStyles] = useState()
	const [generatingType, setGeneratingType] = useState(null)
	const [currentAct, setCurrentAct] = useState(null)
	const [currentScene, setCurrentScene] = useState(null)
	const currentSocket = useRef()
	const [isSaveButtonAllowed, setIsSaveButtonAllowed] = useState(true);
	const [imagesLoadingFlag, setImagesLoadingFlag] = useState(false);
	const currentWebSocketObject = useRef();
	const [disableFlag,setDisableFlag] = useState(false)
	//const hideTokensFillModal = useSelector((state) => state.auth.hideTokensFillModal);
	const imageStylesArray = constants.IMAGESTYLESARRAY;
	const [title, setTitle] = useState('');
	const [titleModal, setTitleModal] = useState(false)
	const [oneImageGenerationCost, setOneImageGenerationCost] = useState(0);
	const [oneSceneImageGen, setOneSceneImageGen] = useState(0);
	const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
	const [openInstantStoryboard, setOpenInstantStoryboard] = useState(false);

	const handleClose = () => {
		setOpen(false);
		setGeneratingType(null)
		setGeneratedLinks([])
		setPrompt('')
		setImageStyles('')
		setCurrentAct('')
		setCurrentScene('')
		setDisableFlag(false)
		setLoading(false)
		setTitleModal(false)
		document.documentElement.style.overflow = 'auto'; // Allow background scrolling
	}

	useEffect( () => {
		document.documentElement.style.overflow = 'hidden'; // Prevent background scrolling
		webSocketConnect();
	
		return () => {
			if (currentWebSocketObject.current) {
				currentWebSocketObject.current?.close();
				currentSocket.current = null;
				currentWebSocketObject.current = null
			}
		};
	 }, []); 

	const getUserTokens = () => {
		axios
		.get(
		  constants.BASE_URL + 'api/auth/get-user-info',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  // console.log(response.data)
		  dispatch(setTokens(response.data?.tokens))
		}).catch(function (error) {
		  // console.log(error);
		});
	  }

	function webSocketConnect () {
		return new Promise((resolve, reject) => {
		const socket = new WebSocket(constants.BASE_WS_URL); // WebSocket server address

        socket.onopen = () => {
            // console.log('WebSocket connection established');
			setDisableFlag(false)
        };

        socket.onmessage = (event) => {
            // console.log('Received message:', event.data);
			let data = event.data
			try {
				data = JSON.parse(event.data); // Parse the JSON string into an object
			} catch (error) {
				console.error('Error parsing JSON:', error);
			}
			// console.log('Received message obj:', data);

			if (data?.type === 'clientId') {
				// console.log('clientID set')
				currentSocket.current = data.clientId
				currentWebSocketObject.current= socket
				resolve(socket)
			}

			if (data?.type === 'images') {
				// console.log('got new images')
				setGeneratedLinks(data.results)
			}
			if (data?.type === 'message' && data?.message === 'first generation') {
				setLoading(false)
				setImagesLoadingFlag(true)
				/* alert.show('First images have been successfully generated! Please wait till generating is over.', {
					type: 'success',
				}); */
			}
			if (data?.type === 'message' && data?.message === 'last generation') {
				/* alert.show('All images were successfully generated!', {
					type: 'success',
				}); */
				setLoading(false)
				setIsSaveButtonAllowed(true)
				setImagesLoadingFlag(false)
				getUserTokens()
				setDisableFlag(false)
			}
            // Handle incoming messages from the WebSocket server
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            // Handle WebSocket connection errors
        };

        socket.onclose = () => {
			setDisableFlag(false)
			setLoading(false)
			setIsSaveButtonAllowed(true)
			setImagesLoadingFlag(false)
			getUserTokens()
            // console.log('WebSocket connection closed');
			currentSocket.current = null
            // Handle WebSocket connection closure
        };

        // Clean up WebSocket connection when the component unmounts
		});
	}

	const handleGenerateByList = async (generationText) => {
		if (generationText === 'story list of acts' && !generatingType) {
			return
		} else if (!generatingType && currentAct === null) {
			return
		}

		if (!currentSocket.current) {
			try {
				const socket = await webSocketConnect();
				if (socket.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}
		
		async function generationFunction () {
			const data = {
				prompt,
				imageStyles,
				generationText
			}

			if (!imageStyles) {
				alert.show(
					`Please fill out all fields.`,
					{ type: 'error' }
				);	
				return
			}

			const charactersToAI = (charactersArr) => charactersArr?.reduce((accumulator,charId) => {
				const character = characters?.find(item => item?.character?.id === charId)
				return accumulator = [...accumulator,character.character]
			},[])

			const settingToAI = (settignArr) => settignArr?.reduce((accumulator,setId) => {
				const settingItem = setting?.find(item => item?.settingItem?.id === setId)
				return accumulator = [...accumulator,settingItem.settingItem]
			},[])

			if (generationText === 'story list of acts') {
				data.scenes = list_of_acts
				data.fieldKey = 'list_of_acts'
				data.characters =  charactersToAI(storyCharacters)
				data.setting = settingToAI(storySetting)
			} else {
				data.fieldKey = 'list_of_scenes'
				data.scenes = acts[currentAct]?.list_of_scenes
				data.characters =  charactersToAI(acts[currentAct]?.actCharacters)
				data.setting = settingToAI(acts[currentAct]?.actSetting)
			}
			
			data.clientId = currentSocket.current

			setLoading(true)
			setIsSaveButtonAllowed(false)

			await axios
			.post(
				constants.BASE_URL +
					'api/ai/generate-scene-images',
				data,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
				// // console.log(response.data)
				// setGeneratedLinks(response.data)
				// alert.show('New images was successfully generated!', {
				// 	type: 'success',
				// });
				// setLoading(false)
				// setIsSaveButtonAllowed(true)
			})
			.catch(function (error) {
				setLoading(false)
				setIsSaveButtonAllowed(true)
				if (error.response?.data === 'error with openAi') {
					alert.show(
					  `Something went wrong with AI server`,
					  { type: 'error' }
					);
				}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
					if (localStorage.getItem('role')=== 'Enterprise User') {
					  alert.show(
						`Problem with the subscription. Please notify your Enterprise Admin.`,
						{ type: 'error' }
					  );
					} else {
					  alert.show(
						`Subsctiption is required for generation`,
						{ type: 'error' }
					  );
					}
				} else if (error.response?.data === 'No scenes to generate images from') {
					alert.show(
						`No text to generate images from. Please add text to the relevant fields`,
						{ type: 'error' }
					);
				} else if (error.response?.data === 'Not enough tokens') {
					if (localStorage.getItem('role') === 'Enterprise User') {
					  alert.show(
						`Not enough AI tokens. Please notify your Enterprise Admin.`,
						{ type: 'error' }
					  );
					} else {
					  alert.show(
						`You don't have enough tokens for this action.`,
						{ type: 'error' }
					  );
					  console.log('tokens missing')
					  setTokensFillModal(true)
					}		
				} else {
					alert.show(
						`Something went wrong with image generation`,
						{ type: 'error' }
					);
					// console.log(error);
				}
			});
		}
	};

	function sendMessageToServer(message) {
		// console.log('ready state',currentWebSocketObject.current)
		if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
			currentWebSocketObject.current.send(message);
			// console.log('Message sent to server:', message);
		} else {
			console.error('WebSocket connection is not open or established.');
		}
	}

	function stopGeneration () {
		sendMessageToServer('stop generation')
		if (currentWebSocketObject.current) {
			// currentWebSocketObject.current?.close();
			setDisableFlag(true)
			currentSocket.current = null;
			currentWebSocketObject.current = null
		}
	}

	useEffect(() => {
        fetchFolders();
    }, [token]);

    const fetchFolders = async () => {
        try {
            const response = await axios.get(`${constants.BASE_URL}api/projects/get-folders/` + projectId(), {
                headers: { Authorization: `Bearer ${token}` },
            });
			//console.log('folders', response.data)
            setFolders(response.data);
		
        } catch (error) {
            console.error('Failed to fetch folders', error);
            alert.show('Failed to fetch folders', { type: 'error' });
        } 
    };

	async function handleSaveImages () {
		const filteredLinks = generatedLinks.filter(item => item !== 'Error while trying to generate image')
		//console.log('projectId', projectId(), 'generated links', generatedLinks, 'filteredlinks', filteredLinks, 'selected folder', selectedFolder)
		setLoading(true)
			await axios
				.post(
					constants.BASE_URL +
						'api/projects/set-images-folder/' +
						projectId(),
					{
						title: selectedFolder,
						imagesLinks: filteredLinks
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					handleClose();
					const params = {};
					for(let entry of searchParams.entries()) {
					  params[entry[0]] = entry[1];
					}
					setGeneratedLinks([])
					setPrompt('')
					alert.show(
						`Images were successfully saved into folder.`,
						{ type: 'success' }
					);
					setLoading(false)
					setSelectedFolder(null)
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('set-image'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
					alert.show(
						`Something went wrong, could not save images.`,
						{ type: 'error' }
					);
					setLoading(false)
				});
		}

	const handleGenerateByScript = async (fieldKey) => {
		if (!generatingType) {
			return
		}

		if (!currentSocket.current) {
			try {
				const socket = await webSocketConnect();
				if (socket.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}

		async function generationFunction () {
			const data = {
				prompt,
				imageStyles,
				fieldKey,
			}

			if (!imageStyles && currentAct && currentScene === null) {
				alert.show(
					`Please fill out all fields.`,
					{ type: 'error' }
				);	
				return
			}
			// console.log('Scenes before processing:', scenes);
			// console.log('Current Act:', currentAct, acts[currentAct]);
			// console.log('Current Scene:', currentScene, acts[currentAct].scenes[currentScene]);
			const newScenes = [acts[currentAct]?.scenes[currentScene]].map(scene => {
				const charactersToAI = (charactersArr) => charactersArr?.reduce((accumulator,charId) => {
					const character = characters?.find(item => item?.character?.id === charId)
					return accumulator = [...accumulator,character]
				},[])
		
				const settingToAI = (settignArr) => settignArr?.reduce((accumulator,setId) => {
					const settingItem = setting?.find(item => item?.settingItem?.id === setId)
					return accumulator = [...accumulator,settingItem]
				},[])
			
				let newScene = {
					characters: charactersToAI(scene?.characters),
					setting: settingToAI(scene?.setting)
				}
				if (fieldKey === 'scene script') {
					newScene.scene_script = scene.scene_full_script
				} else {
					newScene.scene_script = scene.list_of_beats_for_the_scene
				}
				
				return newScene

			})
			
			data.scenes = newScenes
			data.clientId = currentSocket.current

			setLoading(true)
			setIsSaveButtonAllowed(false)

			await axios
				.post(
					constants.BASE_URL +
						'api/ai/generate-scene-images-by-script',
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					// setGeneratedLinks(response.data)
					// alert.show('New images were successfully generated!', {
					// 	type: 'success',
					// });
					// setLoading(false)
					// setIsSaveButtonAllowed(true)
				})
				.catch(function (error) {
					setIsSaveButtonAllowed(true)
					setLoading(false)
					if (error.response?.data === 'error with openAi') {
						alert.show(
						  `Something went wrong with AI server`,
						  { type: 'error' }
						);
					}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
						if (localStorage.getItem('role')=== 'Enterprise User') {
						  alert.show(
							`Problem with the subscription. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`Subsctiption is required for generation`,
							{ type: 'error' }
						  );
						}
									
					  } else if (error.response?.data === 'Not enough tokens') {
						if (localStorage.getItem('role') === 'Enterprise User') {
						  alert.show(
							`Not enough AI tokens. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`You don't have enough tokens for this action.`,
							{ type: 'error' }
						  );
						  setTokensFillModal(true)
						}		
					} else {
						alert.show(
							`Something went wrong with image generation`,
							{ type: 'error' }
						);
						// console.log(error);
					}
				});
		}
	};

	useEffect(()=> {
		dispatch(addLoadingFlag('get-fees'));
		axios
		.get(
		constants.BASE_URL + 'api/fees/get-fees',
		{
			headers: {
			Authorization: `Bearer ${token}`,
			},
		}
		).then(function (response) {
		dispatch(removeLoadingFlag('get-fees'));
		if(response.data.hasOwnProperty('oneImageGenerationCost')){
			setOneImageGenerationCost(response.data.oneImageGenerationCost)
		}
		if(response.data.hasOwnProperty('oneSceneImageGen')){
			setOneSceneImageGen(response.data.oneSceneImageGen)
		}
		}).catch(function (error) {
		if (error.response.data === 'Permission denied') {
			navigate('/#');
		}
		// console.log(error);
		dispatch(removeLoadingFlag('get-fees'));
		});
	},[alert,dispatch,token]);

	return (
		<div>
			<InstantStoryboard
            open={openInstantStoryboard}
            handleClose={() => setOpenInstantStoryboard(false)}
            text={summary}
            projectId={projectId()}
            clientId={currentSocket.current}
			setTokensFillModal={setTokensFillModal}
          />
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
		
				<Box
					component='form'
					noValidate
					sx={style}
				>
				 <>
				 {titleModal && 
					<Modal
					open={titleModal}
					onClose={()=> setTitleModal(false)}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					setTokensFillModal={setTokensFillModal}
				>
					<Box
						component='form'
						noValidate
						sx={style}
					>
						<Typography sx={{ m: 0, p: 2 }}>SAVE TO FOLDER</Typography>
						<IconButton
							aria-label='close'
							onClick={()=> setTitleModal(false)}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
							}}
						>
							<CloseIcon />
						</IconButton>
						{loading && <ModalLoader loading={loading} />}
						<Autocomplete
							freeSolo
							options={folders.map((option) => option.title)}
							value={selectedFolder}
							onChange={(event, newValue) => {
								setSelectedFolder(newValue);
							}}
							onInputChange={(event, newInputValue) => {
								setSelectedFolder(newInputValue);
							}}
							renderInput={(params) => (
								<TextField {...params} label="Select Existing Folder or Create a New Folder" />
							)}
						/>
						<Button
							onClick={() => handleSaveImages()}
							fullWidth
							//disabled={title.trim().length === 0}
							variant='contained'
							sx={{ mt: 3, mb: 2, color: '#fff' }}
						>
							Save
						</Button>
					</Box>
					
				</Modal>
				 }

				<Typography sx={{ m: 0, p: 2 }}>GENERATE IMAGE</Typography>
				{imagesLoadingFlag && <Box sx={{ display: 'flex',position: 'absolute',right: '45px',top: '15px' }}>
					<CircularProgress size={25}/>
				</Box>}
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
					}}
				>
					<CloseIcon />
				</IconButton>
				<Box
					sx={{
						maxHeight: {xs: '100%', md: '65vh'},
						overflow: 'scroll',
						paddingRight: '8px',
					}}
				>
					{loading && <ModalLoader loading={loading} />}
					{generatingType === null && 
					<>
						<Tooltip arrow title={`AI Tokens: ${oneSceneImageGen} x # of images`}>
						<Button
							onClick={() => setOpenInstantStoryboard(true)}
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2, color: '#fff' }}
						>
							Using the Story Summary
						</Button>
						</Tooltip>
						<Tooltip arrow title={`AI Tokens: ${oneSceneImageGen} x # of acts`}>
						<Button
							onClick={() => setGeneratingType('byActsList')}
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2, color: '#fff' }}
						>
							Using the List of Acts
						</Button>
						</Tooltip>
						<Tooltip arrow title={`AI Tokens: ${oneSceneImageGen} x # of scenes`}>
						<Button
							onClick={() => setGeneratingType('byList')}
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2, color: '#fff' }}
						>
							Using the List of Scenes from a Selected Act
						</Button>
						</Tooltip>
						<Tooltip arrow title={`AI Tokens: ${oneSceneImageGen}`}>
						<Button
							onClick={() => setGeneratingType('byBeats')}
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2, color: '#fff' }}
						>
							Using the Scene Beats from a specific scene
						</Button>
						</Tooltip>
						<Tooltip arrow title={`AI Tokens: ${oneSceneImageGen}`}>
						<Button
							onClick={() => setGeneratingType('byScripts')}
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2, color: '#fff' }}
						>
							Using the script or story portion from a specific scene
						</Button>
						</Tooltip>
					</>}  

					{generatingType !== null && 
					<>
					<Button
						onClick={() => {
							setGeneratingType(null)
							setGeneratedLinks([])
						}}
						sx={{
							color: 'white',
							borderRadius: '8px',
							height: '40px',
							textDecoration: 'underline',
						}}
					>
						{'<- Back'}
					</Button>
					<FormControl
						fullWidth
						variant='outlined'
						style={{ marginTop: "10px" }}
					>
						<InputLabel
							variant='outlined'
							id='test-select-label'
							shrink={true}
						>
							Choose a Style for the Image *
						</InputLabel>
						<Select
							value={imageStyles}
							onChange={(e)=> setImageStyles(e.target.value)}
							labelId='test-select-label'
							variant='outlined'
							label='Choose a Style for the Image'
							fullWidth
							notched={true}
							InputLabelProps={{ shrink: true }}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 300,  
										maxWidth: '100%',
										overflow: 'auto', 
									},
								},
							}}
						>
							{imageStylesArray?.map(item => <MenuItem value={item}>{item}</MenuItem>)}
						</Select>
					</FormControl>
					{generatingType !== 'byActsList' &&
					<FormControl
					fullWidth
					variant='outlined'
					style={{ marginTop: "10px" }}
				>
					<InputLabel
						variant='outlined'
						id='act-select-label'
						shrink={true}
					>
						Choose Act for generating
					</InputLabel>
					<Select
						value={currentAct}
						onChange={(e)=> setCurrentAct(e.target.value)}
						labelId='act-select-label'
						variant='outlined'
						label='Choose Act for generating'
						fullWidth
						notched={true}
						InputLabelProps={{ shrink: true }}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 300,  
									maxWidth: '100%',
									overflow: 'auto', 
								},
							},
						}}
					>
						{acts?.map((item,itemIndex) => <MenuItem value={itemIndex}>Act {item?.act_number + 1}</MenuItem>)}
					</Select>
				</FormControl>
					}
					{generatingType !== 'byActsList' && generatingType !== 'byList' &&
					<FormControl
					fullWidth
					variant='outlined'
					style={{ marginTop: "10px" }}
				>
					<InputLabel
						variant='outlined'
						id='act-select-label'
						shrink={true}
					>
						Choose Scene for generating
					</InputLabel>
					<Select
						value={currentScene}
						onChange={(e)=> setCurrentScene(e.target.value)}
						labelId='act-select-label'
						variant='outlined'
						label='Choose Scene for generating'
						fullWidth
						notched={true}
						InputLabelProps={{ shrink: true }}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 300,  
									maxWidth: '100%',
									overflow: 'auto', 
								},
							},
						}}
					>
						{acts[currentAct]?.scenes?.map((scene) => <MenuItem value={scene?.scene_number}>Scene {scene?.scene_number + 1}</MenuItem>)}
					</Select>
				</FormControl>
					}
					<TextField
						margin='normal'
						required
						name='prompt'
						label='Describe what should be in the image:'
						id='prompt'
						placeholder='For best results, focus on the physical attributes of your characters and the scene.'
						fullWidth
						minRows={4}
						maxRows={12}
						multiline
						value={prompt}
						onChange={(e) => {
							setPrompt(e.target.value);
						}}
						InputLabelProps={{ shrink: true }}
					/>
					{generatedLinks.length > 0 && 
						<Box sx={{
							display: 'flex',
							gap: '20px',
							flexWrap: 'wrap',
							justifyContent: 'center'
						}}> 
							{generatedLinks.map(image => <Box sx={{
								display: 'flex',
								justifyContent: 'center',
								width: '100%',
							}}>
									<Box sx={{
										display: 'flex',
										gap: '5px',
										flexDirection: 'column',
										alignItems: 'center',
									}}>
										<CardMedia
											/* component='div'
											sx={{
												height: 210,
												width: 170,
											}}/*  */
											component="img"
											sx={{
												width: '50vh',
                        						height: '50vh',
											}}
											image={image[0]}
											alt={'generated image'}
											onError={(e) => {
												e.target.src = placeholder;
											}}
										/>	
										<Box sx={{
											display: 'flex',
											justifyContent: 'center', // Center the typography horizontally
											width: '100%',
											textAlign: 'center' // Center the text within its container
										}}>
											{image[2] && <Typography sx={{ m: 0, px: 2, py: 1, fontSize: '14px' }}>{image[2]}</Typography>}
										</Box>
									</Box>
							</Box>)}
						</Box>
						}
					</>}  
					

					
					</Box>
					{generatingType !== null && 
					<>
						<Button
						onClick={() => {
							if (imagesLoadingFlag) {
								stopGeneration()
							} else {
								if (generatingType === 'byList') handleGenerateByList('story list of scenes')
								if (generatingType === 'byActsList') handleGenerateByList('story list of acts')
								if (generatingType === 'byScripts') handleGenerateByScript('scene script')
								if (generatingType === 'byBeats') handleGenerateByScript('scene beats')
							}
						}}
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2, color: '#fff' }}
						disabled={disableFlag}
					>
						{imagesLoadingFlag ? 'stop next generating' : 'generate'}
					</Button>
					</>}
				
				
					
					{
						generatedLinks.length > 0 && 
						<Button
							onClick={() => setTitleModal(true)}
							fullWidth
							variant='contained'
							sx={{ mt: 0, mb: 2, color: '#fff' }}
							disabled={!isSaveButtonAllowed}
						>
							Save
						</Button>
					}
					
					</>
				</Box>
			</Modal>
		</div>
	);
}
