import axios from 'axios';
import { useState, useRef } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
} from '@mui/material';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import { logout, setTokens } from '../../../store/slices/authSlice';
import * as constants from '../../../helpers/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import deepObjectCompare from '../../../helpers/deepObjectCompare';
import StopIcon from '@mui/icons-material/Stop';
import { styled } from '@mui/material/styles';
import AddNewImage from '../../modals/AddNewImage';
import Loader from '../../Loader';
import { ThemeConsumer } from 'styled-components';


const style = {
	width: '100%',
	display: 'flex',
	'flex-direction': 'column',
};

const fieldContainerStyles = {
	display: 'grid',
	gridTemplateColumns: 'auto 1fr',
	justifyContent: 'space-between',
	gap: '0.5rem',
};

const GenerationButton = styled(Button)({
	height: '46px',
	width: '46px',
	marginTop: '22px',
	marginBottom: '8px',
	fontSize: '36px',
	});

export default function EditCharacter({
	character,
	handleProjectHeaderVisibility,
	setCardsToRenderFunc,
	handleExportPdf,
	doesCardHaveUnsavedData,
	currentProject,
	setTokensFillModal
}) {
	const [loading, setLoading] = useState(false);
	const stateLoading = useSelector((state) => state.loading.loading);
	const dispatch = useDispatch();
	const alert = useAlert();
	const token = useSelector((state) => state.auth.token);
	const navigate = useNavigate();
	const typesArray = ['Main', 'Major', 'Supporting'];
	const typeInitialState = typesArray.findIndex(
		(item) => character?.type === item
	);

	// // console.log(character);
	const [type, setType] = useState(typeInitialState);
	const [name, setName] = useState(character?.name);
	const [age, setAge] = useState(character?.age);
	const [origin, setOrigin] = useState(character?.origin);
	const [skills, setSkills] = useState(character?.skills);
	const [look, setLook] = useState(character?.look);
	const [relationships, setRelationships] = useState(character?.relationships);
	const [motivation, setMotivation] = useState(character?.motivation);
	const [gender, setGender] = useState(character?.gender);
	const [emotions, setEmotions] = useState(character?.emotions);
	const [backstory, setBackstory] = useState(character?.backstory);
	const currentSocket = useRef()
	const currentWebSocketObject = useRef();
	const currentGenerationSetFunc = useRef()
	const [currentGenerationFieldKey,setCurrentGenerationFieldKey] = useState()
	const [isGenerationButtonAllowed, setIsGenerationButtonAllowed] = useState(true);
	//const hideTokensFillModal = useSelector((state) => state.auth.hideTokensFillModal);
	const [addNewImageOpen, setAddNewImageOpen] = useState(false);
	const [characters, setCharacters] = useState([]);
	const [setting, setSetting] = useState([]);
	const location = useLocation();
	const projectId = location.pathname.slice(9, location.pathname.length);

	const [characterState, setCharacterState] = useState({
		name: 0,
		age: 0,
		gender: 0,
		origin: 0,
		skills: 0,
		look: 0,
		relationships: 0,
		motivation: 0,
		emotions: 0,
		backstory: 0,
	  });
	
	useEffect( () => {
		webSocketConnect();
	
		return () => {
			if (currentWebSocketObject.current) {
				currentWebSocketObject.current?.close();
				currentSocket.current = null;
				currentWebSocketObject.current = null
			}
		
		};
	 }, []); 

	 const getUserTokens = () => {
		axios
		.get(
		  constants.BASE_URL + 'api/auth/get-user-info',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  // console.log(response.data)
		  dispatch(setTokens(response.data?.tokens))
		}).catch(function (error) {
		  // console.log(error);
		});
	  }

	async function getCharacters() {
		await axios
		.get(
			constants.BASE_URL +
				'api/projects/get-characters/' +
				projectId,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then(function (response) {
			setCharacters(response.data.map((character, index) => {
						return {
							character: character,
							characterId: index,
						};
					})
			) 
		})}

	async function getSetting() {
			await axios
			.get(
				constants.BASE_URL +
					'api/projects/get-setting/' +
					projectId,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
				setSetting(response.data.map((settingItem, index) => {
							return {
								settingItem: settingItem,
								settingItemId: index,
							};
						})
				) 
			})}
	
	 async function webSocketConnect () {
		return new Promise((resolve, reject) => {
			const socket = new WebSocket(constants.BASE_WS_URL); // WebSocket server address
			socket.onopen = () => {
				// console.log('WebSocket connection established');
			};

			socket.onmessage = (event) => {
				// // console.log('Received message:', event.data);
				let data = event.data
				try {
					data = JSON.parse(event.data); // Parse the JSON string into an object
				} catch (error) {
					console.error('Error parsing JSON:', error);
				}
				// // console.log('Received message obj:', data);

				if (data?.type === 'clientId') {
					// console.log('clientID set')
					currentSocket.current = data.clientId
					currentWebSocketObject.current= socket
					resolve(socket)
				}

				if (data?.type === 'content') {
					// // console.log('got new content')
					currentGenerationSetFunc.current(prev => prev + data.content)
				}
				if (data?.type === 'message' && data?.message === 'first generation') {
					setLoading(false)
					currentGenerationSetFunc.current('')
				}
				if (data?.type === 'message' && data?.message === 'last generation') {
					/* alert.show(`Text was successfully generated`, {
						type: 'success',
					}); */
					setLoading(false)
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
					getUserTokens()
				}
			
            // Handle incoming messages from the WebSocket server
			};

			socket.onerror = (error) => {
				console.error('WebSocket error:', error);
				// Handle WebSocket connection errors
			};

			socket.onclose = () => {
				// console.log('WebSocket connection closed');
				currentSocket.current = null
				setIsGenerationButtonAllowed(true)
				setCurrentGenerationFieldKey('');
				// Handle WebSocket connection closure
			};

		});
	}
	
	const handleFillFieldWithAI = async (fieldKey) => {
		if (!currentSocket.current) {
			try {
				await webSocketConnect();
				if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}

		async function generationFunction () {
			setIsGenerationButtonAllowed(false)
			const allFields = {
				name,
				age,
				gender,
				origin,
				skills,
				look,
				relationships,
				motivation,
				emotions,
				backstory,
			};
			const allSetFieldFunctions = [
				setName,
				setAge,
				setGender,
				setOrigin,
				setSkills,
				setLook,
				setRelationships,
				setMotivation,
				setEmotions,
				setBackstory,
			];
			const currentFieldSetFunctionIndex = Object.keys(allFields).findIndex(
				(key) => key === fieldKey
			);
			
			let baseFields = { ...allFields };

			const objectWithoutCurrentField = Object.keys(allFields)
				.filter((key) => key !== fieldKey && allFields[key])
				.reduce((obj, key) => {
					return Object.assign(obj, {
						[key]: allFields[key],
					});
				}, {});
	
			currentGenerationSetFunc.current = allSetFieldFunctions[currentFieldSetFunctionIndex];
			setCurrentGenerationFieldKey(fieldKey);
			setLoading(true);
			await axios
				.post(
					constants.BASE_URL + 'api/ai/generate-character-field',
					{
						baseFields,
						fieldToFill: fieldKey,
						projectId: currentProject?._id,
						projectDescription: currentProject?.quickstartSummary,
						clientId: currentSocket.current
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)

				.then(function (response) {
					// allSetFieldFunctions[currentFieldSetFunctionIndex](
					// 	response.data
					// );
					// setLoading(false);
					// // console.log(response);
					// alert.show(
					// 	`Text was successfully generated`,
					// 	{ type: 'success' }
					// );
				})
				.catch(function (error) {
					if (error.response?.data === 'error with openAi') {
						alert.show(
						  `Something went wrong with the AI server.`,
						  { type: 'error' }
						);
					}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
						if (localStorage.getItem('role')=== 'Enterprise User') {
						  alert.show(
							`Problem with subscription. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`A subscription is required for generation.`,
							{ type: 'error' }
						  );
						}
									
					  } else if (error.response?.data === 'Not enough tokens') {
						if (localStorage.getItem('role') === 'Enterprise User') {
						  alert.show(
							`Not enough AI tokens. Please notify your Enterprise Admin.`,
							{ type: 'error' }
						  );
						} else {
						  alert.show(
							`You don't have enough tokens for this action.`,
							{ type: 'error' }
						  );
						  setTokensFillModal(true)
						}		
					} else {
						alert.show(
							`Something went wrong with text generation.`,
							{ type: 'error' }
						);
						// console.log(error);
					}
					setLoading(false);
					setIsGenerationButtonAllowed(true)
					setCurrentGenerationFieldKey('');
				});
		}
		
	};

	function sendMessageToServer(message) {
		if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
			currentWebSocketObject.current.send(message);
			// console.log('Message sent to server:', message);
		} else {
			console.error('WebSocket connection is not open or established.');
		}
	}

	function stopGeneration () {
		sendMessageToServer('stop generation')
	}

	function checkIfCardHasUnsavedData () {
		if (!character) return

		const newCardData = {
			name,
		}

		if (age) newCardData.age = age;
		if (gender) newCardData.gender = gender;
		if (origin) newCardData.origin = origin;
		if (skills) newCardData.skills = skills;
		if (look) newCardData.look = look;
		if (relationships) newCardData.relationships = relationships;
		if (motivation) newCardData.motivation = motivation;
		if (emotions) newCardData.emotions = emotions;
		if (backstory) newCardData.backstory = backstory;
		if (type) newCardData.type = typesArray[type];
		
		const isEqual = deepObjectCompare(newCardData,character)

		if (!isEqual) {
			doesCardHaveUnsavedData.current = true
		} else {
			doesCardHaveUnsavedData.current = false
		}
	}

	checkIfCardHasUnsavedData()

	useEffect(() => {
		setType(typeInitialState ?? '');
		setName(character?.name ?? '');
		setAge(character?.age ?? '');
		setGender(character?.gender ?? '');
		setOrigin(character?.origin ?? '');
		setSkills(character?.skills ?? '');
		setLook(character?.look ?? '');
		setRelationships(character?.relationships ?? '');
		setMotivation(character?.motivation ?? '');
		setEmotions(character?.emotions ?? '');
		setBackstory(character?.backstory ?? '');
	}, [character, typeInitialState]);

	const handleChange = (event) => {
		setType(event.target.value);
	};

	const handleSubmit = async (e) => {
		if (stateLoading) {
			return
		}
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		data.append('type', typesArray[type]);

		if (data.get('name')) {
			dispatch(addLoadingFlag('update-character'));
			await axios
				.post(
					constants.BASE_URL +
						'api/projects/update-character-by-id/' +
						character.id,
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					alert.show(
						`'${
							data.get('name')
						}' was successfully changed!`,
						{ type: 'success' }
					);
					dispatch(removeLoadingFlag('update-character'));
					setCardsToRenderFunc();
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('update-character'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						dispatch(logout());
					}
				
						alert.show(
							`Something went wrong, could not add illustrations.`,
							{ type: 'error' }
						);
					
				});
		}
	};

	useEffect(()=> {
		dispatch(addLoadingFlag('get-fees'));
		axios
		.get(
		  constants.BASE_URL + 'api/fees/get-fees',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  dispatch(removeLoadingFlag('get-fees'));
		  if(response.data.hasOwnProperty('characters')){
			setCharacterState(response.data.characters)
		  }
		}).catch(function (error) {
		  if (error.response.data === 'Permission denied') {
			navigate('/#');
		  }
		  // console.log(error);
		  dispatch(removeLoadingFlag('get-fees'));
		});
	  },[alert,dispatch,token])

	return (
		<Box component='form' onSubmit={handleSubmit} noValidate sx={style}>
				<>
				{loading && <Loader />}
				<AddNewImage
				setCardsToRenderFunc={setCardsToRenderFunc}
				setTokensFillModal={setTokensFillModal}
				open={addNewImageOpen}
				setOpen={setAddNewImageOpen}
				//episodesArray={episodesArray.current}
				//cardsToRenderLength={cardsToRender.length}
				setting={setting}
				characters={characters}
				//stories=""
				//setImagesToRenderFunc={setImagesToRenderFunc}
			/>
					<Box
						sx={{
							overflow: 'scroll',
							paddingRight: '8px',
						}}
					>
						<FormControl
							fullWidth
							variant='outlined'
							style={{ marginTop: 6 }}
						>
							<InputLabel
								variant='outlined'
								id='test-select-label'
							>
								Type of Character *
							</InputLabel>
							<Select
								value={type}
								onChange={handleChange}
								labelId='test-select-label'
								variant='outlined'
								inputProps={{ 'aria-label': 'Without label' }}
								label='Type of Character *'
								fullWidth
							>
								<MenuItem value={0}>Main</MenuItem>
								<MenuItem value={1}>Major</MenuItem>
								<MenuItem value={2}>Supporting</MenuItem>
							</Select>
						</FormControl>
						<Box sx={fieldContainerStyles}>
						<Tooltip arrow title={`AI Tokens: ${characterState.name}`}>
						<GenerationButton
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('name')}
								variant='contained'
								component='label'
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'name') : false}
							>
								{(loading && currentGenerationFieldKey === 'name') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'name') ? <StopIcon /> : ('🤖'))}
						</GenerationButton>
						</Tooltip>
							<TextField
								margin='normal'
								required
								fullWidth
								id='name'
								label='Name'
								name='name'
								//autoFocus
								disabled={currentGenerationFieldKey === 'name' ? true : false}
								// defaultValue={character?.name}
								placeholder="Your Character's Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
								InputLabelProps={{ shrink: true }}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
							<Tooltip arrow title={`AI Tokens: ${characterState.age}`}>
							<GenerationButton
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('age')}
								variant='contained'
								component='label'
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'age') : false}
							>
								{(loading && currentGenerationFieldKey === 'age') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'age') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							</Tooltip>
							<TextField
								margin='normal'
								fullWidth
								name='age'
								placeholder='Their Age'
								label='Age'
								id='age'
								defaultValue={character?.age}
								value={age}
								onChange={(e) => setAge(e.target.value)}
								InputLabelProps={{ shrink: true }}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
								disabled={currentGenerationFieldKey === 'age' ? true : false}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
							<Tooltip arrow title={`AI Tokens: ${characterState.gender}`}>
							<GenerationButton
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('gender')}
								variant='contained'
								component='label'
								
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'gender') : false}
							>
								{(loading && currentGenerationFieldKey === 'gender') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'gender') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							</Tooltip>
							<TextField
								margin='normal'
								fullWidth
								name='gender'
								label='Gender'
								id='gender'
								placeholder='Their Gender'
								disabled={currentGenerationFieldKey === 'gender' ? true : false}
								// defaultValue={character?.gender}
								value={gender}
								InputLabelProps={{ shrink: true }}
								onChange={(e) => setGender(e.target.value)}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
							<Tooltip arrow title={`AI Tokens: ${characterState.origin}`}>
							<GenerationButton
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('origin')}
								variant='contained'
								component='label'
								
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'origin') : false}
							>
								{(loading && currentGenerationFieldKey === 'origin') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'origin') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							</Tooltip>
							<TextField
								margin='normal'
								fullWidth
								name='origin'
								label='Where are they from?'
								id='origin'
								multiline
								minRows={1}
								maxRows={4}
								placeholder='Their Origin'
								defaultValue={character?.origin}
								disabled={currentGenerationFieldKey === 'origin' ? true : false}
								value={origin}
								InputLabelProps={{ shrink: true }}
								onChange={(e) => setOrigin(e.target.value)}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
						<Tooltip arrow title={`AI Tokens: ${characterState.look}`}>
							<GenerationButton
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('look')}
								variant='contained'
								component='label'
								
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'look') : false}
							>
								{(loading && currentGenerationFieldKey === 'look') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'look') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							</Tooltip>
							<TextField
								margin='normal'
								fullWidth
								name='look'
								label='What is their physical description?'
								id='look'
								disabled={currentGenerationFieldKey === 'look' ? true : false}
								defaultValue={character?.look}
								multiline
								minRows={4}
								InputLabelProps={{ shrink: true }}
								placeholder='Write a short description about their physical appearance. Make note of any stand-out features or accessories.'
								value={look}
								onChange={(e) => setLook(e.target.value)}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
						<Tooltip arrow title={`AI Tokens: ${characterState.skills}`}>
							<GenerationButton
								onClick={() => !isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('skills')}
								variant='contained'
								component='label'
								sx={{
									
								}}
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'skills') : false}
							>
								{(loading && currentGenerationFieldKey === 'skills') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'skills') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							</Tooltip>
							<TextField
								margin='normal'
								fullWidth
								name='skills'
								label='Is there something unique about them? Unique skills?'
								id='skills'
								defaultValue={character?.skills}
								multiline
								minRows={4}
								disabled={currentGenerationFieldKey === 'skills' ? true : false}
								InputLabelProps={{ shrink: true }}
								placeholder='Write about their unique skills or abilities.'
								value={skills}
								onChange={(e) => setSkills(e.target.value)}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
						<Tooltip arrow title={`AI Tokens: ${characterState.relationships}`}>
							<GenerationButton
								onClick={() =>
									!isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('relationships')
								}
								variant='contained'
								component='label'
								sx={{
									
								}}
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'relationships') : false}
							>
								{(loading && currentGenerationFieldKey === 'relationships') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'relationships') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							</Tooltip>
							<TextField
								margin='normal'
								fullWidth
								name='relationships'
								label='What are their relationships?'
								id='relationships'
								defaultValue={character?.relationships}
								multiline
								disabled={currentGenerationFieldKey === 'relationships' ? true : false}
								minRows={4}
								InputLabelProps={{ shrink: true }}
								placeholder= "Who are the significant others in this character's life? Spouse, friends, enemies, rivals? What are their relationships like?"
								value={relationships}
								onChange={(e) => setRelationships(e.target.value)}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
						<Tooltip arrow title={`AI Tokens: ${characterState.motivation}`}>
							<GenerationButton
								onClick={() =>
									!isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('motivation')
								}
								variant='contained'
								component='label'
								sx={{
									
								}}
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'motivation') : false}
							>
								{(loading && currentGenerationFieldKey === 'motivation') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'motivation') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							</Tooltip>
							<TextField
								margin='normal'
								fullWidth
								name='motivation'
								label='What motivates this character to action?'
								id='motivation'
								defaultValue={character?.motivation}
								multiline
								disabled={currentGenerationFieldKey === 'motivation' ? true : false}
								minRows={4}
								InputLabelProps={{ shrink: true }}
								placeholder='What is driving this character forward in the story? What makes them get up in the morning?'
								value={motivation}
								onChange={(e) => setMotivation(e.target.value)}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
						<Tooltip arrow title={`AI Tokens: ${characterState.backstory}`}>
							<GenerationButton
								onClick={() =>
									!isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('backstory')
								}
								variant='contained'
								component='label'
								sx={{
									
								}}
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'backstory') : false}
							>
								{(loading && currentGenerationFieldKey === 'backstory') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'backstory') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							</Tooltip>
							<TextField
								margin='normal'
								fullWidth
								name='backstory'
								label="What is this character's backstory?"
								id='backstory'
								defaultValue={character?.backstory}
								multiline
								minRows={4}
								disabled={currentGenerationFieldKey === 'backstory' ? true : false}
								maxRows={12}
								InputLabelProps={{ shrink: true }}
								placeholder='Where were they raised? How were they raised? Where were they educated or trained? What events have shaped who they are?'
								value={backstory}
								onChange={(e) => setBackstory(e.target.value)}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
							/>
						</Box>

						<Box sx={fieldContainerStyles}>
						<Tooltip arrow title={`AI Tokens: ${characterState.emotions}`}>
							<GenerationButton
								onClick={() =>
									!isGenerationButtonAllowed ? stopGeneration() : handleFillFieldWithAI('emotions')
								}
								variant='contained'
								component='label'
								sx={{
									
								}}
								disabled={!isGenerationButtonAllowed ? !(!loading && currentGenerationFieldKey === 'emotions') : false}
							>
								{(loading && currentGenerationFieldKey === 'emotions') ? (<CircularProgress size={25}/>) : ((!loading && currentGenerationFieldKey === 'emotions') ? <StopIcon /> : ('🤖'))}
							</GenerationButton>
							</Tooltip>
							<TextField
								margin='normal'
								fullWidth
								name='emotions'
								label='What emotional traits and reactions does this character display?'
								id='emotions'
								disabled={currentGenerationFieldKey === 'emotions' ? true : false}
								defaultValue={character?.emotions}
								multiline
								minRows={4}
								InputLabelProps={{ shrink: true }}
								maxRows={12}
								placeholder='How does this character react to others or during conflict? Are they calm and stoic, or hot-headed and emotional?'
								value={emotions}
								onChange={(e) => setEmotions(e.target.value)}
								onFocus={()=>handleProjectHeaderVisibility(false)}
								onBlur={()=>handleProjectHeaderVisibility(true)}
							/>
						</Box>
					</Box>
					<Box
						sx={(theme) => ({
							display: 'flex',
							justifyContent: 'flex-end',
							position: 'fixed',
							bottom: '0',
							right: '0',
							width: '100%',
							paddingRight: {xs:'50px', md:'35px'},
							backgroundColor: theme.palette.background.default,
							//boxShadow: '0px 0px 0px 1px black',
							zIndex: '1',
						})}
					>
						<Button
							fullWidth
							variant='contained'
							sx={{
								height: {xs:'55px', md:'inherit'},
								lineHeight: {xs: '1.2', md: 'inherit'},
								marginLeft: '10px',
								mt: 1,
								mb: 1,
								color: '#fff',
								width: 'fit-content',
								alignSelf: 'self-end',
								marginRight: '10px'
							}}
							disabled={!isGenerationButtonAllowed}
							onClick={() => {
								getCharacters();
								getSetting();
								setAddNewImageOpen(true);
							  }}
						>
							Create Image of Character
						</Button>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{
								height: {xs:'55px', md:'inherit'},
								lineHeight: {xs: '1.2', md: 'inherit'},
								mt: 1,
								mb: 1,
								color: '#fff',
								width: 'fit-content',
								'align-self': 'self-end',
								marginRight: '10px'
							}}
							disabled={!isGenerationButtonAllowed}
						>
							Save
						</Button>
						<Button
							fullWidth
							variant='contained'
							sx={{
								height: {xs:'55px', md:'inherit'},
								lineHeight: {xs: '1.2', md: 'inherit'},
								mt: 1,
								mb: 1,
								color: '#fff',
								width: 'fit-content',
								'align-self': 'self-end',
								marginRight: '30px'
							}}
							onClick={() =>
								handleExportPdf()
							}
						>
								Export PDF
						</Button>
					</Box>
				</>
		</Box>
	);
}
