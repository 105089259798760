import React, { useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import CardMedia from '@mui/material/CardMedia';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/NativeSelect';
import useQuery from '../../helpers/hooks/useQuery';
import AddNewEpisode from '../modals/AddNewEpisode';
import DeleteEpisode from '../modals/DeleteEpisode';
import ChangeEpisode from '../modals/ChangeEpisode';
import * as constants from '../../helpers/constants';
import TabChangeModal from '../modals/TabChangeModal';
import { alpha, useTheme } from '@mui/material/styles';

const pages = [
    {
        title: 'Characters',
        link: 'characters',
    },
    {
        title: 'Setting',
        link: 'setting',
    },
    {
        title: 'Story',
        link: 'story',
    },
    {
        title: 'Images',
        children: [
            {
                title: 'Image Generation',
                link: 'images',
            },
            {
                title: 'Instant Storyboard',
                link: 'folders',
            },
            {
                title: 'Comic Page Creator',
                link: 'pages',
            },
        ],
    },
];

function ProjectHeader({ currentProject, setEpisodesToRender, episodesArray, handleExportPdf, doesCardHaveUnsavedData,isProjectHeaderVisible}) {
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();
	const [anchorElUser, setAnchorElUser] = useState(null);
	const [deleteEpisodeOpen, setDeleteEpisodeOpen] = useState(false);
	const [addNewEpisodeOpen, setAddNewEpisodeOpen] = useState(false);
	const [changeEpisodeOpen, setChangeEpisodeOpen] = useState(false);
	const [openTabModal, setOpenTabModal] = useState(false);
	const [openPageModal, setOpenPageModal] = useState(false);
	const query = useQuery();
	const currentLink = useRef();
	const episodesQueryValue = query.get('episodes');
	const itemQueryValue = query.get('item');
	const navigate = useNavigate();
	const projectId = location.pathname.slice(9, location.pathname.length);
	const [episodes, setEpisodes] = useState(
		episodesQueryValue ? episodesQueryValue : 0
	);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openMenu, setOpenMenu] = useState(null);
	const theme = useTheme();

	const handleMenuItemClick = (page) => {
		if (!page.children) {
			onNavigateLinkClick(page.link);
		}
	};

	const renderMenuItems = (page, handleClose) => {
		if (page.children) {
			return (
				<Menu
					id={`menu-${page.title}`}
					anchorEl={anchorEl}
					open={Boolean(anchorEl && openMenu === page.title)}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': `button-${page.title}`,
					}}
				>
					{page.children.map((item) => (
						<MenuItem key={item.title} onClick={() => {
							handleClose();
							onNavigateLinkClick(item.link);
						}}>
							{item.title}
						</MenuItem>
					))}
				</Menu>
			);
		}
	};

	const handleEpisodesChange = (event) => {
		setEpisodes(event.target.value);
		const params = {};
		for (let entry of searchParams.entries()) {
			params[entry[0]] = entry[1];
		}
		setSearchParams({
			...params,
			episodes: event.target.value,
			item: null,
		});
	};

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const onNavigateLinkClick = (link) => {
		currentLink.current = link
		
		if (doesCardHaveUnsavedData.current) {
			setOpenTabModal(true)
		} else {
			changeSearchParams()
		}
	};

	const onNavigateToProjectInfo = () => {
		navigate('/project-info/'+ projectId)
	}

	const onProjectAvatarLinkClick = (link) => {
		currentLink.current = link
		
		if (doesCardHaveUnsavedData.current) {
			setOpenPageModal(true)
		} else {
			onNavigateToProjectInfo()
		}
	};

	const changeSearchParams = () => {
		const params = {};
		for (let entry of searchParams.entries()) {
			params[entry[0]] = entry[1];
		}
		setSearchParams({ ...params, page: currentLink.current, item: null });
	}
	let headerFlag = true

	if (!isProjectHeaderVisible) {
		headerFlag = itemQueryValue === 'null' || typeof itemQueryValue === 'object' ? true : false
	}

	const isPageActive = (page, currentPage) => {
		if (page.link === currentPage) {
			return true;
		}
		if (page.children) {
			return page.children.some(child => child.link === currentPage);
		}
		return false;
	};

	return (
		<>
			<AppBar
				position='fixed'
				sx={(theme) => ({
					height: '100px',
					top: {
						xs: headerFlag  ? '60px' : '-85px',
						md: '60px'
					},
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: theme.palette.background.top,
					transition: '0.4s',
					width: '100%',
					zIndex: '99'
				})}
			>
				<TabChangeModal open={openTabModal} setOpen={setOpenTabModal} onSubmit={changeSearchParams}/>
				<TabChangeModal open={openPageModal} setOpen={setOpenPageModal} onSubmit={onNavigateToProjectInfo}/>
				<Container maxWidth='xl'>
					<Toolbar disableGutters>
						<DeleteEpisode
							open={deleteEpisodeOpen}
							setOpen={setDeleteEpisodeOpen}
							setEpisodes={setEpisodes}
							setEpisodesToRender={setEpisodesToRender}
							episodesArray={episodesArray}
						/>
						<ChangeEpisode
							open={changeEpisodeOpen}
							setOpen={setChangeEpisodeOpen}
							setEpisodesToRender={setEpisodesToRender}
							episodesArray={episodesArray}
						/>
						<AddNewEpisode
							open={addNewEpisodeOpen}
							setOpen={setAddNewEpisodeOpen}
							setEpisodesToRender={setEpisodesToRender}
						/>
						{currentProject.image && (
							<Box
								onClick={() => onProjectAvatarLinkClick()}
								sx={(theme) => ({
									position: 'relative',
									height: {
										xs: 85,
										md: 90,
									},
									minWidth: 65,
									width: {
										xs: 65,
										md: 90,
									}, 
									background: `linear-gradient(to bottom left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
									cursor: 'pointer',
									overflow: 'hidden',
									mr: {xs: '2px', md: '10px'}, 
									ml: {xs: '-8px', md: '0px'},
									borderRadius: '4px',
								})}
							>
								<CardMedia
									component='div'
									sx={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									zIndex: 2,
									}}
									image={
										constants.IMAGE_URL + currentProject?.image
									}
									onClick={() => onProjectAvatarLinkClick()}
									alt='#'
								/>
								<Typography
								sx={{
									position: 'absolute',
									top: {
										xs: '20%',
										md: '25%',
									},
									//left: '35%',
									left: '0',
									right: '0',
									textAlign: 'center',
									fontSize: '30px',
									textTransform: 'uppercase',
									color: 'white',
									zIndex: 1,
								}}
							>
									{currentProject?.title?.split(/\s/).slice(0, 3).map(word => word[0]).join('')}
							</Typography>
							</Box>)}
						<Box
							sx={{
								flexGrow: 1,
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Box 
								sx={{
									//flexGrow: 1,
									//display: 'flex',
									flexDirection: 'row',
									pt: {xs:'7px', md: '10px'},
									//width: '11%'
								}}>
								{/* <Tooltip
									title={currentProject?.title}
									placement='bottom-start'
								> */}
									<Typography
										variant='h6'
										noWrap
										component='span'
										sx={{
											mr: 0,
											/* display: {
												xs: 'block',
												md: 'block',
											}, */
											flexGrow: 1,
											fontFamily: 'Roboto',
											fontWeight: 700,
											letterSpacing: '',
											color: 'white',
											textDecoration: 'none',
											paddingLeft: '4px',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											marginBottom: '0',
											whiteSpace: 'nowrap',
											WebkitLineClamp: '3',
											width: {
												xs: '600px',
												md: '70vw',
											},
											WebkitBoxOrient: 'vertical',
										}}
									>
										{currentProject?.title}
									</Typography>
								{/* </Tooltip> */}
								
									<Tooltip arrow title='Open settings'>
										<IconButton
											onClick={handleOpenUserMenu}
											sx={{ p: 0, mb: '2px' }}
										>
											<Avatar
												sx={{
													width: 24,
													height: 24,
													bgcolor: 'transparent',
												}}
											>
												<MoreVertIcon
													style={{ color: '#fff' }}
												/>
											</Avatar>
										</IconButton>
									</Tooltip>
									<Menu
										disableScrollLock={true}
										sx={{ mt: '20px' }}
										id='menu-appbar'
										anchorEl={anchorElUser}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left',
										}}
										open={Boolean(anchorElUser)}
										onClose={handleCloseUserMenu}
									>
										{/* <MenuItem
											onClick={() =>
												setAddNewEpisodeOpen(true)
											}
										>
											<Typography textAlign='center'>
												New Episode
											</Typography>
										</MenuItem> */}
										<MenuItem
											onClick={() =>
												handleExportPdf()
											}
										>
											<Typography textAlign='center'>
												Export PDF
											</Typography>
										</MenuItem>
										<MenuItem
											onClick={() =>
												navigate('/project-info/' + projectId)
											}
										>
											<Typography textAlign='center'>
												Project Info Page
											</Typography>
										</MenuItem>
										{/* {episodesQueryValue !== '0' && (
											<MenuItem
												onClick={() =>
													setDeleteEpisodeOpen(true)
												}
											>
												<Typography textAlign='center'>
													Delete Episode
												</Typography>
											</MenuItem>
										)}
										{episodesQueryValue !== '0' && (
											<MenuItem
												onClick={() =>
													setChangeEpisodeOpen(true)
												}
											>
												<Typography textAlign='center'>
													Edit Episode
												</Typography>
											</MenuItem>
										)}
										{pages.map((page) => (
											<MenuItem
												key={page.title}
												onClick={() => {
													onNavigateLinkClick(
														page.link
													);
												}} 
												sx={{
													display: {
														md: 'none',
													},
												}}
											>
												<Typography textAlign='center'>
													{page.title}
												</Typography>
											</MenuItem>
										))}*/}
									</Menu>
								</Box>
							
							<Box
								sx={{
									width: {
										xs: '600px',
										md: '70vw',
									},
									textOverflow: 'ellipsis',
								}}
							>
								{/* <Tooltip
									title={currentProject?.tagline}
									placement='bottom-start'
								> */}
									<Typography
										variant='h5'
										noWrap
										component='span'
										sx={{
											mr: 1,
											display: 'block',
											flexGrow: 1,
											fontFamily: 'Roboto',
											color: 'white',
											textDecoration: 'none',
											paddingLeft: '10px',
											marginBottom: '0px',
											fontSize: '0.875rem',
											//marginTop: '5px',
											fontWeight: '500',
											letterSpacing: '0.02857em',
											//lineHeight: '1.75',
											textTransform: 'uppercase',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										{currentProject?.tagline}
									</Typography>
								{/* </Tooltip> */}
							</Box>
							{/* <div style={{ display: 'flex' }}>
								<FormControl
									sx={{
										m: 1,
										width: 'max-content',
										display: {
											xs: 'Block',
											md: 'Block',
										},
									}}
								>
									<Select
										labelid='demo-simple-select-helper-label'
										id='demo-simple-select-helper'
										sx={{
											paddingRight: '5px',
										}}
										value={episodes}
										onChange={handleEpisodesChange}
									>
										<option value={0}>All episodes</option>
										{episodesArray.map((episode, index) => (
											<option
												key={index}
												value={index + 1}
											>
												{episode?.title}
											</option>
										))}
									</Select>
								</FormControl>  */}
								
							{/* </div> */}
							<Box sx={{ width: 'max-content' }}>
								<Box
									sx={{
										flexGrow: 1,
										display: {
											//xs: 'none',
											xs: 'flex',
											alignItems: 'center',
										},
									}}
								>
									{pages.map((page) => (
                        <div key={page.title}>
                            <Button
                                id={`button-${page.title}`}
                                aria-controls={`menu-${page.title}`}
                                aria-haspopup="true"
                                aria-expanded={openMenu === page.title ? 'true' : undefined}
                                onClick={(event) => {
									if (page.children) {
										// If there are children, prepare to show a dropdown
										setAnchorEl(event.currentTarget);
										setOpenMenu(page.title);
									} else {
										// No children means direct navigation
										onNavigateLinkClick(page.link);
										window.scrollTo(0, 0);
									}
								}}
											sx={(theme) => ({
												marginLeft: { xs: '0px', md: '10px' },
												display: 'flex',
												borderRadius: '4px',
												mt: {xs:'6px', md: '6px'},
												mb: '6px',
												width: '100%',
												//height: {xs:'30px', md: '35px'},
												color: isPageActive(page, query.get('page')) ? theme.palette.text.primary : theme.palette.text.secondary,
                                				backgroundColor: isPageActive(page, query.get('page')) ? `${alpha(theme.palette.primary.main, 0.25)} !important` : 'none',
                                				marginRight: {xs: '-5px', md: '10px'},
											})}
										>
											{page.title}
                            </Button>
                            {page.children && renderMenuItems(page, () => {
                                setAnchorEl(null);
                                setOpenMenu(null);
                            })}
                        </div>
                    ))}
								</Box>
							</Box>
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
			{/* <div style={{ height: '86px' }}></div> */}
		</>
	);
}
export default ProjectHeader;
