import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, Autocomplete, CardMedia } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import * as constants from '../../../helpers/constants';
import { useSelector, useDispatch } from 'react-redux';
import { setTokens } from '../../../store/slices/authSlice';
import { useAlert } from 'react-alert';
import ModalLoader from '../../ModalLoader';
import {
	addLoadingFlag,
	removeLoadingFlag,
} from '../../../store/slices/loadingSlice';
import placeholder from '../../../assets/images/placeholder.png';

const style = {
    position: 'absolute',
    top: '46%',
    left: '50%',
	height: '85vh',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    outline: 'none',
    px: 2,
    pb: 4,
    width: {
        xs: '100vw',
        md: '60vw',
    },
};

export default function InstantStoryboard({ open, handleClose, text, projectId, clientId, setTokensFillModal }) {
    const [numImages, setNumImages] = useState(5);
    const [imageStyle, setImageStyle] = useState('');
    const [prompt, setPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const alert = useAlert();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
	const [titleModal, setTitleModal] = useState(false);
    const [oneImageGenerationCost, setOneImageGenerationCost] = useState(0);
	const [oneSceneImageGen, setOneSceneImageGen] = useState(0);
	const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const currentSocket = useRef()
    const currentWebSocketObject = useRef()
    const [generatedLinks, setGeneratedLinks] = useState([]);
    const [imagesLoadingFlag, setImagesLoadingFlag] = useState(false);
    const [disableFlag,setDisableFlag] = useState(false)
    const [isSaveButtonAllowed, setIsSaveButtonAllowed] = useState(true);
    const imageStylesArray = constants.IMAGESTYLESARRAY;

	useEffect( () => {
		//document.documentElement.style.overflow = 'hidden'; // Prevent background scrolling
		webSocketConnect();
		return () => {
			if (currentWebSocketObject.current) {
				currentWebSocketObject.current?.close();
				currentSocket.current = null;
				currentWebSocketObject.current = null
			}
		};
	 }, []); 

    const getUserTokens = () => {
		axios
		.get(
		  constants.BASE_URL + 'api/auth/get-user-info',
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		).then(function (response) {
		  // console.log(response.data)
		  dispatch(setTokens(response.data?.tokens))
		}).catch(function (error) {
		  // console.log(error);
		});
	  }

    function webSocketConnect () {
		return new Promise((resolve, reject) => {
		const socket = new WebSocket(constants.BASE_WS_URL); // WebSocket server address

        socket.onopen = () => {
            console.log('WebSocket connection established');
			setDisableFlag(false)
        };

        socket.onmessage = (event) => {
            //console.log('Received message:', event.data);
			let data = event.data
			try {
				data = JSON.parse(event.data); // Parse the JSON string into an object
			} catch (error) {
				console.error('Error parsing JSON:', error);
			}
			//console.log('Received message obj:', data);

			if (data?.type === 'clientId') {
				console.log('clientID set')
				currentSocket.current = data.clientId
				currentWebSocketObject.current= socket
				resolve(socket)
			}

			if (data?.type === 'images') {
				console.log('got new images')
				setGeneratedLinks(data.results)
				//console.log('generated links', generatedLinks)
			}
			if (data?.type === 'message' && data?.message === 'first generation') {
				setLoading(false)
				setImagesLoadingFlag(true)
				alert.show('First images have been successfully generated! Please wait till generating is over.', {
					type: 'success',
				}); 
			}
			if (data?.type === 'message' && data?.message === 'last generation') {
				alert.show('All images were successfully generated!', {
					type: 'success',
				}); 
				setLoading(false)
				setIsSaveButtonAllowed(true)
				setImagesLoadingFlag(false)
				getUserTokens()
				setDisableFlag(false)
			}
            // Handle incoming messages from the WebSocket server
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            // Handle WebSocket connection errors
        };

        socket.onclose = () => {
			setDisableFlag(false)
			setLoading(false)
			setIsSaveButtonAllowed(true)
			setImagesLoadingFlag(false)
			getUserTokens()
            console.log('WebSocket connection closed');
			currentSocket.current = null
            // Handle WebSocket connection closure
        };

        // Clean up WebSocket connection when the component unmounts
		});
	}

    //console.log(text, numImages, imageStyle, prompt, projectId);
    const handleGenerateInstantStoryboard = async () => {
        if (!currentSocket.current) {
			try {
				const socket = await webSocketConnect();
				if (socket.readyState === WebSocket.OPEN) {
					generationFunction();
				}
			} catch (error) {
				console.error('Error connecting to WebSocket:', error);
			}
		} else {
			generationFunction();
		}
		
		async function generationFunction () {
			const data = {
				prompt,
				imageStyles: imageStyle,
                text,
                numImages,
			}

			if (!imageStyle || !numImages) {
				alert.show(
					`Please select the Number of Images and the Image Style.`,
					{ type: 'error' }
				);	
				return
			}
			
			data.clientId = currentSocket.current

			setLoading(true)
			setIsSaveButtonAllowed(false)

			await axios
			.post(
				constants.BASE_URL +
					'api/ai/generate-instant-storyboard',
				data,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
				//console.log(response.data)
				//setGeneratedLinks(response.data)
				// alert.show('New images was successfully generated!', {
				// 	type: 'success',
				// });
				// setLoading(false)
				// setIsSaveButtonAllowed(true)
			})
			.catch(function (error) {
				setLoading(false)
				setIsSaveButtonAllowed(true)
				if (error.response?.data === 'error with openAi') {
					alert.show(
					  `Something went wrong with AI server`,
					  { type: 'error' }
					);
				}  else if (error.response?.data === 'subscription not active' || error.response?.data === 'subscription not bought') {
					if (localStorage.getItem('role')=== 'Enterprise User') {
					  alert.show(
						`Problem with the subscription. Please notify your Enterprise Admin.`,
						{ type: 'error' }
					  );
					} else {
					  alert.show(
						`Subsctiption is required for generation`,
						{ type: 'error' }
					  );
					}
								
				  } else if (error.response?.data === 'Not enough tokens') {
					if (localStorage.getItem('role') === 'Enterprise User') {
					  alert.show(
						`Not enough AI tokens. Please notify your Enterprise Admin.`,
						{ type: 'error' }
					  );
					} else {
					  alert.show(
						`You don't have enough tokens for this action.`,
						{ type: 'error' }
					  );
					  setTokensFillModal(true)
					}		
				} else {
					alert.show(
						`Something went wrong with image generation`,
						{ type: 'error' }
					);
					// console.log(error);
				}
			});
		}
	};

	function sendMessageToServer(message) {
		// console.log('ready state',currentWebSocketObject.current)
		if (currentWebSocketObject.current.readyState === WebSocket.OPEN) {
			currentWebSocketObject.current.send(message);
			// console.log('Message sent to server:', message);
		} else {
			console.error('WebSocket connection is not open or established.');
		}
	}

	function stopGeneration () {
		sendMessageToServer('stop generation')
		if (currentWebSocketObject.current) {
			// currentWebSocketObject.current?.close();
			setDisableFlag(true)
			currentSocket.current = null;
			currentWebSocketObject.current = null
		}
	}

    useEffect(()=> {
		//dispatch(addLoadingFlag('get-fees'));
		axios
		.get(
		constants.BASE_URL + 'api/fees/get-fees',
		{
			headers: {
			Authorization: `Bearer ${token}`,
			},
		}
		).then(function (response) {
            //console.log('fees', response.data)
		dispatch(removeLoadingFlag('get-fees'));
		/* if(response.data.hasOwnProperty('oneImageGenerationCost')){
			setOneImageGenerationCost(response.data.oneImageGenerationCost)
		} */
		if(response.data.hasOwnProperty('oneSceneImageGen')){
			setOneSceneImageGen(response.data.oneSceneImageGen)
		}
		}).catch(function (error) {
		if (error.response.data === 'Permission denied') {
			navigate('/#');
		}
		console.log(error);
		dispatch(removeLoadingFlag('get-fees'));
		});
	},[token]);

    useEffect(() => {
        fetchFolders();
    }, []);

    const fetchFolders = async () => {
		//console.log('fetching folders', projectId)
        try {
            const response = await axios.get(`${constants.BASE_URL}api/projects/get-folders/` + projectId, {
                headers: { Authorization: `Bearer ${token}` },
            });
			//console.log('folders', response.data)
            setFolders(response.data);
		
        } catch (error) {
            console.error('Failed to fetch folders', error);
            alert.show('Failed to fetch folders', { type: 'error' });
        } 
    };

	async function handleSaveImages () {
		const filteredLinks = generatedLinks.filter(item => item !== 'Error while trying to generate image')
		//console.log('projectId', projectId, 'generated links', generatedLinks, 'filteredlinks', filteredLinks, 'selected folder', selectedFolder)
		setLoading(true)
			await axios
				.post(
					constants.BASE_URL +
						'api/projects/set-images-folder/' +
						projectId,
					{
						title: selectedFolder,
						imagesLinks: filteredLinks
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(function (response) {
					handleClose();
					const params = {};
					/* for(let entry of searchParams.entries()) {
					  params[entry[0]] = entry[1];
					} */
					setGeneratedLinks([])
					setPrompt('')
					alert.show(
						`Images were successfully saved into folder.`,
						{ type: 'success' }
					);
					setLoading(false)
					setSelectedFolder(null)
				})
				.catch(function (error) {
					dispatch(removeLoadingFlag('set-image'));
					if (error.response?.data === 'User does not exist.') {
						navigate('login');
						//dispatch(logout());
					}
					alert.show(
						`Something went wrong, could not save images.`,
						{ type: 'error' }
					);
					setLoading(false)
				});
		}

    return (
        <div>
        <Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
		
				<Box
					component='form'
					noValidate
					sx={style}
				>
				 <>
				 {titleModal && 
					<Modal
					open={titleModal}
					onClose={()=> setTitleModal(false)}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
				>
					<Box
						component='form'
						noValidate
						sx={style}
					>
						<Typography sx={{ m: 0, p: 2 }}>SAVE TO FOLDER</Typography>
						<IconButton
							aria-label='close'
							onClick={()=> setTitleModal(false)}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
							}}
						>
							<CloseIcon />
						</IconButton>
						{loading && <ModalLoader loading={loading} />}
						<Autocomplete
							freeSolo
							options={folders.map((option) => option.title)}
							value={selectedFolder}
							onChange={(event, newValue) => {
								setSelectedFolder(newValue);
							}}
							onInputChange={(event, newInputValue) => {
								setSelectedFolder(newInputValue);
							}}
							renderInput={(params) => (
								<TextField {...params} label="Select Existing Folder or Create a New Folder" />
							)}
						/>
						<Button
							onClick={() => handleSaveImages()}
							fullWidth
							//disabled={title.trim().length === 0}
							variant='contained'
							sx={{ mt: 3, mb: 2, color: '#fff' }}
						>
							Save
						</Button>
					</Box>
					
				</Modal>
				 }
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Typography variant="h6" m='20px' textAlign='center'>
                    Instant Storyboard
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
				<Box
					sx={{
						maxHeight: {xs: '100%', md: '75vh'},
						overflow: 'scroll',
						paddingRight: '8px',
					}}
				>
                <TextField
					margin="normal"
					required
					fullWidth
					id="numImages"
					label="The Number of Images You Want to Generate"
					type="number"
					value={numImages}
					onChange={(e) => {
						const value = e.target.value;
						if (value >= 0) { // Ensure the value is positive
							setNumImages(value);
						}
					}}
				/>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="image-style-label">Choose a Style for the Images</InputLabel>
                    <Select
						required
                        labelId="image-style-label"
                        id="imageStyle"
                        value={imageStyle}
                        onChange={(e) => setImageStyle(e.target.value)}
                        label="Choose a Style for the Images"
                    >
                        {imageStylesArray.map((style) => (
                            <MenuItem key={style} value={style}>
                                {style}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
						margin='normal'
						name='prompt'
						label='Optional: Adjust the generated storyboard images with a comment'
						id='prompt'
						placeholder='For best results, focus on the physical or tangible elements of your story.'
						fullWidth
						minRows={4}
						maxRows={12}
						multiline
						value={prompt}
						onChange={(e) => {
							setPrompt(e.target.value);
						}}
						InputLabelProps={{ shrink: true }}
					/>
                {generatedLinks.length > 0 && 
					<Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'center' }}> 
						{generatedLinks.map((image, index) => (
							<Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
								<Box sx={{ display: 'flex', gap: '5px', flexDirection: 'column', alignItems: 'center' }}>
									<CardMedia
										component="img"
										sx={{ md: { width: '50vh', height: '50vh' }, xs: { width: '100%', height: '100%' } }}
										image={image[0]} // Display the image link
										alt={'generated image'}
										onError={(e) => { e.target.src = placeholder; }}
									/>    
									<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center' }}>
										{image[2] && <Typography sx={{ m: 0, px: 2, py: 1, fontSize: '14px' }}>{image[2]}</Typography>}
									</Box>
								</Box>
							</Box>
						))}
					</Box>
}

                <Tooltip arrow title={`AI Tokens: ${oneSceneImageGen * numImages}`}>
                <Button
                    onClick={handleGenerateInstantStoryboard}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: '#fff' }}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Generate'}
                </Button>
                </Tooltip>
				{
						generatedLinks.length > 0 && 
						<Button
							onClick={() => setTitleModal(true)}
							fullWidth
							variant='contained'
							sx={{ mt: 0, mb: 2, color: '#fff' }}
							disabled={!isSaveButtonAllowed}
						>
							Save
						</Button>
					}
            </Box>
			</Box>
        </Modal>
        </>
        </Box>
        </Modal>
        </div>
    );
}
