import React, { useEffect, useRef, useState } from 'react';
import {
  AppBar, Box, Toolbar, IconButton, Typography, Menu, MenuItem, Button, Tooltip, Container, CardMedia, Avatar,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Divider
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { logout, setTokens } from '../../store/slices/authSlice';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import * as constants from '../../helpers/constants';
import aiCoins from '../../assets/images/aiCoins.svg';
import { StyledBadge } from './style';
import logoLong from '../../assets/images/logo-long.png';
import UserInfo from '../../pages/UserInfo';
import AutoFillTokensModal from '../../components/modals/AutoFillTokensModal';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as Discord } from '../../assets/images/discord-logo-blue.svg';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';

const pages = [
  {
    title: 'Projects',
    link: '/project-gallery',
  },
   {
    title: 'Analysis',
    link: '/analysis'
 },
 {
    title: 'Publish',
    link: '/publish'
  },
];
if (localStorage.getItem('role') === 'Enterprise Admin') {
  pages.push({
    title: 'Team Management',
    link: '/user-mgmt'
  });
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: theme.spacing(2),
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  '& svg': {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

function Header(currentProject) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const generationTokens = useSelector((state) => state.auth.generationTokens);
  const openNav = Boolean(anchorElNav);
  const openUser = Boolean(anchorElUser);
  const [user, setUser] = useState();
  const rerenderFlag = useRef()
  const theme = useTheme();
  const [tokensFillModal, setTokensFillModal] = useState(false);

  useEffect(()=> {
    getUser()
  },[])

  rerenderFlag.current = !rerenderFlag.current

  const [openUserDialog, setOpenUserDialog] = useState(false);

  const handleUserDialogToggle = () => {
    setOpenUserDialog(!openUserDialog);
    //console.log('local storage',localStorage)
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleHelpDialogToggle = () => {
    setOpenHelpDialog(!openHelpDialog);
    //console.log('local storage',localStorage)
  };

  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClickOpenHelpDialog = () => {
    setOpenHelpDialog(true);
  };

  const handleCloseHelpDialog = () => {
    setOpenHelpDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogout = () => {
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('tutorialFlag');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('hideTokensFillModal');
    localStorage.removeItem('email');
    localStorage.removeItem('access_token');
    localStorage.removeItem('userId');
    localStorage.removeItem('tutorialFlag');
    localStorage.removeItem('role');
    localStorage.removeItem('hideTokensFillModal');
    dispatch(logout());
    setAnchorElUser(null);
    setAnchorElNav(null)
    navigate('/login')
  };

  const getUser = () => {
    axios
    .get(
      constants.BASE_URL + 'api/auth/get-user-info',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(function (response) {
      setUser(response.data)
      dispatch(setTokens(response.data?.tokens))
    }).catch(function (error) {
      console.log(error);
    });
  }

  return (
    <>
    <AutoFillTokensModal 
      open={tokensFillModal} 
      setOpen={setTokensFillModal} 
      currentProjectName={currentProject?.title || undefined}
      noCountdown={true}
    />
    <Dialog
        open={openUserDialog}
        onClose={handleUserDialogToggle}
        fullWidth
        maxWidth="md"
      >
        <UserInfo dialog={true} />
      </Dialog>
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>{"AI Tokens Information"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          AI Tokens are used for all AI generation on our platform. 
          <br/><br/>On Desktop, you can hover over the generate button to see a more accurate token cost.
          <br/><br/>Here is our general price guide. Some fields may be more or less expensive:
          <br/><br/>Text Generation
          <br/>Small Fields: 10 Tokens
          <br/>Medium Fields: 30-50 Tokens
          <br/>Large Fields: 70-80 Tokens
          <br/><br/>Image Generation
          <br/>Images: 70 Tokens per Image
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', paddingBottom:"30px"}}>
        
        <Button onClick={() => setTokensFillModal(true)} variant='contained' color="primary">
          Purchase Tokens
        </Button>
      </DialogActions>
    </Dialog>
    <StyledDialog open={openHelpDialog} onClose={handleCloseHelpDialog} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h4" component="div" gutterBottom>
          How Can We Help?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          We're here to assist you with any questions or feedback you may have. Choose an option below:
        </Typography>
        
        <IconWrapper>
          <Link href="mailto:support@Storybuilt.ai" color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
            <EmailIcon />
            <Typography variant="h6" sx={{ ml: 1 }}>Email Support</Typography>
          </Link>
        </IconWrapper>
        <Typography variant="body1" paragraph>
          Click the icon above or email us at:{" "}
          <Link href="mailto:support@storybuilt.ai" color="primary">
            support@storybuilt.ai
          </Link>
        </Typography>
        
        <Divider sx={{ my: 2 }} />
        
        <IconWrapper>
          <HelpOutlineIcon />
          <Typography variant="h6">FAQs</Typography>
        </IconWrapper>
        <Typography variant="body1" paragraph>
          Check out our frequently asked questions for quick answers.
        </Typography>
        <Button 
          variant="outlined" 
          color="primary" 
          startIcon={<HelpOutlineIcon />}
          onClick={() => {
            // Implement navigation to FAQ page here
            navigate('/FAQs');
            handleCloseHelpDialog();
          }}
        >
          View FAQs
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseHelpDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
    <AppBar position="fixed" sx={{height: '62.5px', backgroundColor: theme.palette.background.top, width: '100%'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{justifyContent: {xs: 'space-between'}}}>
          <CardMedia
              component="img"
              sx={{
                  height: 40,
                  width: 'auto',
                  cursor: 'pointer'
                }}
              image={logoLong}
              alt="#"
              onClick={() => {location.pathname !== '/login' && navigate('/projects'); window.scrollTo(0, 0)}}
          />
           {/*  <CardMedia
              component="img"
              sx={{
                  height: 25,
                  width: 'auto',
                  cursor: 'pointer'
                }}
              image={logoTextOnly}
              alt="#"
              onClick={() => location.pathname !== '/login' && navigate('/projects')}
          /> 
          <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} /> */}
          {(location.pathname !== '/login') && <Box sx={{ display: { xs: 'flex', md: 'none' }, ml: '0' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>}
          <Menu
              disableScrollLock={ true }
              id="menu-appbar"
              anchorEl={anchorElNav}
              // anchorOrigin={{
              //   vertical: 'top',
              //   horizontal: 'right',
              // }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={openNav}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {
                (location.pathname === '/register' || location.pathname === '/contact' || location.pathname === '/forgot-password' || location.pathname === '/reset-password' ) && 
                <MenuItem  onClick={() => {
                  handleCloseNavMenu()
                  navigate('/login')
                }}>
                <Typography textAlign="center">Sign in</Typography>
              </MenuItem>
              }

              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') && pages.map((page) => (
                <MenuItem key={page.title} onClick={() => {
                    handleCloseNavMenu()
                    navigate(page.link)
                  }}>
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
               
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') && 
                <MenuItem onClick={() => {
                  handleUserDialogToggle();
                  handleCloseNavMenu()
                }}>
                  <Typography textAlign="center">Account Info</Typography>
                </MenuItem>
              }
                 {/* {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && localStorage.getItem('role') === 'Enterprise Admin') && 
                <MenuItem onClick={() => navigate('/user-mgmt')}>
                  <Typography textAlign="center">Team Management</Typography>
                </MenuItem>
              } */}
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') && 
                <MenuItem onClick={() => {
                  handleHelpDialogToggle();
                  handleCloseNavMenu()
                }}>
                  <Typography textAlign="center">Help</Typography>
                </MenuItem>
              }
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password' && user?.role === 'Admin') && 
                <MenuItem onClick={() => {
                  handleCloseNavMenu()
                  navigate('/admin')
                }}>
                  <Typography textAlign="center">Admin</Typography>
                </MenuItem>
              }
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') && 
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              }
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') && 
                <MenuItem onClick={() => {
                  handleCloseNavMenu()
                  navigate('/whats-new')
                }}>
                  <Typography textAlign="center">What's New</Typography>
                </MenuItem>
              }
            </Menu>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {(location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') && pages.map((page) => (
              <Button
                key={page.title}
                onClick={() => {
                  handleCloseNavMenu()
                  navigate(page.link)
                }}
                sx={{ fontSize: 18, ml: 2, color: 'white', display: 'block',"&:hover": {
                  backgroundColor: 'inherit'
                } }}
              >
                {page.title}
              </Button>
            ))}
            {(location.pathname === '/register' || location.pathname === '/contact' || location.pathname === '/forgot-password' || location.pathname === '/reset-password') && 
              <Button
                onClick={() => {
                  handleCloseNavMenu()
                  navigate('/login')
                }}
                sx={{ my: 2, color: 'white', display: 'block',"&:hover": {
                  backgroundColor: 'inherit'
                } }}
              >
                Sign in
              </Button>
            }
          </Box>
          {user?.role !== 'Enterprise User' && (
          <Tooltip arrow title="Tokens for Generation">
            <Typography sx={{ marginRight: '10px', cursor: 'pointer' }} onClick={handleClickOpenDialog}>
              <img src={aiCoins} alt="AI Tokens" style={{ width: '35px', height: '35px', verticalAlign: 'middle', marginRight: '0px' }} />
              {generationTokens}
            </Typography>
          </Tooltip>)}
          {(location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') && <Box sx={{ flexGrow: 0, display: {xs: 'none', md: 'block'} }}>
            <Tooltip arrow title="Open Settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
              >
                 <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                  >
                    <Avatar  src="/static/images/avatar/1.jpg" />
                  </StyledBadge>
              </StyledBadge>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '43px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openUser}
              onClose={handleCloseUserMenu}
            >
               
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') && 
                <MenuItem onClick={() => {handleUserDialogToggle(); handleCloseUserMenu();}}>
                  <Typography textAlign="center">Account Info</Typography>
                </MenuItem>
              }
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password' && user?.role === 'Enterprise Admin') && 
                <MenuItem onClick={() => navigate('/user-mgmt')}>
                  <Typography textAlign="center">Team Management</Typography>
                </MenuItem>
              }
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password' && user?.role === 'Admin') && 
                <MenuItem onClick={() => navigate('/admin')}>
                  <Typography textAlign="center">Admin</Typography>
                </MenuItem>
              }
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') && 
                <MenuItem onClick={() => {
                  handleCloseUserMenu()
                  navigate('/whats-new')
                }}>
                  <Typography textAlign="center">What's New</Typography>
                </MenuItem>
              }
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') && 
                <MenuItem onClick={() => {handleHelpDialogToggle(); handleCloseUserMenu();}}>
                  <Typography textAlign="center">Help</Typography>
                </MenuItem>
              }
              {(location.pathname !== '/register' && location.pathname !== '/login' && location.pathname !== '/contact' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') && 
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              }
            </Menu>
          </Box>}
        </Toolbar>
      </Container>
    </AppBar>
    <div style={{height: '10px'}}></div>
    </>
  );
}
export default Header;
