import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import * as constants from './helpers/constants';
import { useState, useMemo, useEffect } from 'react';
import GlobalStyle from './GlobalStyles';

const lightTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem'  // Set the font size for all tooltips
        }}}},
  palette: {
    mode: 'light',
    primary: {
      main: '#ffffff', 
    },
    secondary: {
      main: '#ffffff', 
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: constants.PRIMARY_DARK, 
      main2: constants.PRIMARY, // Look into bringing this into compliance
    },
    secondary: {  
      main: constants.SECONDARY, 
    },
    background: {
      default: constants.BLUE_GREY, 
      alt: constants.DARK_GREY,
      top: constants.BLACK,
    },
    text: {
      primary: constants.TEXT, 
    },
    common: {
      white: '#ffffff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // General button text color override
          color: '#ffffff',
        }}},
    MuiInputLabel: { // This targets all InputLabel components
      styleOverrides: {
        root: { // The 'root' key targets the base style of the label
          fontSize: '1.25rem', // Set the font size as desired
        }}},
    MuiOutlinedInput: {
      styleOverrides: {
            notchedOutline: {
              //borderColor: constants.PRIMARY,
              fontSize: '1.25rem',
            }}},
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem'  // Set the font size for all tooltips
        }}}},
});

function Root() {
  const [mode, setMode] = useState('dark');

  const theme = useMemo(() => (mode === 'light' ? lightTheme : darkTheme), [mode]);

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', theme.palette.primary.main);
  }, [theme]);

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyle theme={theme} />
        <App toggleTheme={toggleTheme} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ReactDOM.createRoot(document.getElementById('root')).render(<Root />);
